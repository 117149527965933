import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { ClaimService } from 'src/app/_services/claim.service';
import { Claim } from 'src/app/_model/claim';
import { ContractService } from 'src/app/_services/contract.service';
import { Subscription } from 'rxjs';
import { Contract } from 'src/app/_model/contract';
import { Attachment } from 'src/app/_model/attachment';
import { ToastrService } from 'ngx-toastr';
import { CurrencyPipe } from '@angular/common';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-claim-information',
  templateUrl: './claim-information.component.html',
  styleUrls: ['./claim-information.component.css'],
})
export class ClaimInformationComponent implements OnInit, OnDestroy {
  @Output() messageEvent = new EventEmitter<boolean>();
  @Output() repairOrderNumberEvent = new EventEmitter<string>();
  @Output() newClaimEvent = new EventEmitter<string>();
  selectedQuestion1 = false;
  selectedQuestion2 = false;
  selectedQuestion3 = false;
  claim: Claim;
  contractsSubscription: Subscription;
  contractSubscription: Subscription;
  claimSubscription: Subscription;
  sellerDataSubscription: Subscription;
  sellerDataSubscription1: Subscription;
  contracts: Contract[] = [];
  contract: Contract;
  startNewClaimForm: FormGroup;
  lossDateEmptyError: boolean = false;
  lossOdometerEmptyError: boolean = false;

  lossDateIncorrectError: boolean = false;
  lossOdometerIncorrectError: boolean = false;
  submitted: boolean = false;

  attachments: Attachment[] = [];
  uploadForm: FormGroup;
  initialClaimExist = false;
  initialClaimId: string;
  email: FormControl;
  showSpinner: boolean = false;
  showSpinnerDraft: boolean = false;
  showLaborGuideOtherDesc: boolean = false;

  formattedAmount;
  amountBeforeFormatting: number = 0;
  billingRateAmountError: boolean = false;
  formattedFax: String;
  faxError: boolean = false;

  odometerError: boolean = false;
  lossOdometerValue;
  lossDateMin: string;
  lossDateMax: string;
  lossOdometerMax: number;
  lossOdometerMin: number;
  editClaim: Claim;
  indexNum = 0;
  header: string = 'Delete Claim';
  message: string = '';
  buttonMessage: string = 'Delete';
  disableDelete: boolean = false;
  saveAsDraftPressed: boolean = false;

  showRoError: boolean = false;
  roErrorMessage: string = "";
  roCheckSpinner: boolean = false;
  roNumberFromExistingClaim: string = "";

  ratesCheck: boolean = false;
  disableLaborBillingRate: boolean = false;
  accountNumber: string = "";
  laborBillingRateFormatted: string = "";
  laborTax: number = 0;
  partTax: number = 0;
  partMarkup: number = 0;
  payeeNameLabel: string = "";
  paymentMethod: string = "";

  lastValidRONum: string = "";
  defaultEmail: string = "";
  defaultFax: string = "";
  emails: any[] = [];
  faxes: any[] = [];
  hidePaymentsSection: boolean = false;
  billingEditable: boolean = true;

  LOCK_CALLS_UPDATE: boolean = false;
  LOCK_CALLS_UNLOCK: boolean = false;

  sellerDataReceived: boolean = false;
  billingEditableSubscription: Subscription;

  constructor(
    private readonly fb: FormBuilder,
    private claimService: ClaimService,
    private contractService: ContractService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private currencyPipe: CurrencyPipe,
    public datepipe: DatePipe,
    private router: Router
  ) {
    this.email = new FormControl('', [
      Validators.required,
      Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$'),
    ]);
    this.startNewClaimForm = this.fb.group({
      lossDate: [, Validators.required],
      lossOdometer: [, Validators.required],
      modificationsDesc: [, Validators.required],
      commercialUseDesc: [, Validators.required],
      anyModificationsBoolean: [, Validators.required],
      isCarTowedIn: [, Validators.required],
      commercialUse: [, Validators.required],
      repairOrderNumber: new FormControl('', Validators.required),
      billingMethod: new FormControl('', Validators.required),
      billingMethodValue: new FormControl('', Validators.required),
      payMethod: new FormControl('Credit Card', Validators.required),
      email: this.email,
      fax: [, Validators.required],
      laborBillingRate: [{ disabled: this.disableLaborBillingRate }, Validators.required],
      laborGuide: [, Validators.required],
      laborGuideOther: [, Validators.required],
      payeeName: [, Validators.required],
    });

    this.claim = { id: '', lossDate: new Date(), lossOdometer: 0 };
  }

  transformAmount(element) {
    this.billingRateAmountError = false;
    const searchCurrency = '$';
    const replaceWith = '';
    const searchComma = ',';
    let amountTmp = this.formattedAmount
      ?.split(searchCurrency)
      .join(replaceWith)
      .split(searchComma)
      .join(replaceWith);
    if (isNaN(+amountTmp) || +amountTmp < 0) {
      this.billingRateAmountError = true;
      return;
    }
    let amount = this.currencyPipe.transform(amountTmp, '$');
    this.formattedAmount = amount;
    element.target.value = amount;
  }

  validateOdometer() {
    this.odometerError = false;
    let odometerTmp = this.lossOdometerValue
      ?.replace('miles', '')
      .replace(' ', '');
    if (!odometerTmp || isNaN(+odometerTmp)) {
      this.odometerError = true;
      return;
    }
    if (
      odometerTmp > this.lossOdometerMax ||
      odometerTmp < this.lossOdometerMin
    ) {
      this.odometerError = true;
      return;
    }
    this.lossOdometerValue = odometerTmp + ' miles';
  }
  transformFax(event, plainFax?) {
    if (!event && !plainFax) return;
    let newVal;
    if (event)
      newVal = event?.target?.value?.replace(/\D/g, '');
    if (plainFax)
      newVal = plainFax?.value?.replace(/\D/g, '');
    if (event && newVal?.length < 10) {
      this.faxError = true;
      newVal = newVal.substring(0, newVal.length - 1);
      return;
    }
    if (event)
      this.faxError = false;
    if (newVal?.length === 0) {
      newVal = '';
    } else if (newVal?.length <= 3) {
      newVal = newVal?.replace(/^(\d{0,3})/, '($1)');
    } else if (newVal?.length <= 6) {
      newVal = newVal?.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
    } else if (newVal?.length <= 10) {
      newVal = newVal?.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
    } else {
      newVal = newVal?.substring(0, 10);
      newVal = newVal?.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
    }
    if (event) {
      this.formattedFax = newVal;
      this.startNewClaimForm.controls.fax.setValue(newVal);
    }
    return newVal;
  }
  ngOnInit(): void {
    this.toastr.clear();
    if (this.claimService.previewActiveClaim) {
      this.startNewClaimForm.disable();
    }

    this.disableDelete = this.claimService.previewActiveClaim;
    this.accountNumber = this.claimService.accountNumber;

    this.startNewClaimForm.controls.payeeName.setValue(
      this.claimService.accountName
    );

    this.accountNumber = this.claimService.accountNumber;


    if (!this.claimService.editClaim) {
      this.contractsSubscription = this.contractService.contracts.subscribe(
        (value) => {
          this.contracts = value;
        }
      );
      this.contractSubscription = this.contractService.contract.subscribe(
        (value) => {
          this.contract = value;
          this.getLaborInfoByLossDate();

          this.lossDateMin = this.contract.effectiveDate;
          this.lossDateMax = this.contract.expirationDate;

          this.lossDateMin = this.datepipe.transform(
            this.lossDateMin,
            'yyyy-MM-dd'
          );

          let expirationDate = new Date(this.contract.expirationDate);
          if (expirationDate.getTime() <= new Date().getTime())
            this.lossDateMax = this.datepipe.transform(
              this.lossDateMax,
              'yyyy-MM-dd'
            );
          else {
            this.lossDateMax = this.datepipe.transform(
              new Date(),
              'yyyy-MM-dd'
            );
          }
          this.lossOdometerMin = this.contract.effectiveOdometer;
          this.lossOdometerMax = this.contract.expirationOdometer;

        }
      );

      if (this.claimService.startClaimFromSearch) {
        this.initialClaimId = null;
        this.initialClaimExist = false;
      } else {
        this.claimSubscription = this.claimService.claim.subscribe((value) => {
          this.initialClaimId = value.id;
          this.initialClaimExist = true;
          this.roNumberFromExistingClaim = value.repairOrderNumber;
        });
      }

      this.sellerDataSubscription = this.claimService.sellerDataReceived.subscribe((value) => {
        if (!this.claimService.editClaim && value && !this.sellerDataReceived) {
          this.sellerDataReceived = value;
          this.emails = this.claimService.emailsSeller;
          this.faxes = this.claimService.faxesSeller;
          this.payeeNameLabel = this.claimService.payeeNameLabel;
          this.paymentMethod = this.claimService.paymentMethod;
          this.billingEditable = this.claimService.billingEditable;
          this.startNewClaimForm.controls.payMethod.setValue(this.paymentMethod);

          this.doubleCheckBillingMethod();
        }
      });

      //edit claim
    } else {

      this.claimSubscription = this.claimService.claim.subscribe((claim) => {
        this.editClaim = claim;
        this.initialClaimId = this.editClaim.id;
        this.initialClaimExist = true;
        this.roNumberFromExistingClaim = claim.repairOrderNumber;

        let contractInfo = {
          lastName: claim?.lastName,
          vin: claim?.vin,
          contractNumber: claim?.contractNumber,
        };
        if (this.editClaim.lossDate) {
          let date = new Date(this.editClaim.lossDate);
          let dateOffset = new Date().getTimezoneOffset() / 60;
          let tmpDate: Date = date;

          tmpDate.setHours(date.getHours() + dateOffset + 1);

          this.startNewClaimForm.controls.lossDate.setValue(
            this.datepipe.transform(tmpDate, 'yyyy-MM-dd')
          );
        }
        this.contractService
          .searchContract(contractInfo)
          .subscribe((resultContract: any) => {
            for (
              let index = 0;
              index < resultContract?.Contracts?.length;
              index++
            ) {
              const element: Contract = resultContract.Contracts[index];
              if (element.contractNumber === claim.contractNumber) {
                this.contract = element;

                this.getLaborInfoByLossDate();

                this.lossOdometerMin = element.effectiveOdometer;
                this.lossOdometerMax = element.expirationOdometer;

                let expirationDate = new Date(element.expirationDate);
                if (expirationDate.getTime() <= new Date().getTime())
                  this.lossDateMax = this.datepipe.transform(
                    element.expirationDate,
                    'yyyy-MM-dd'
                  );
                else
                  this.lossDateMax = this.datepipe.transform(
                    new Date(),
                    'yyyy-MM-dd'
                  );
                this.lossDateMin = this.datepipe.transform(
                  element.effectiveDate,
                  'yyyy-MM-dd'
                );
              }
            }
          });

        if (this.editClaim.lossOdometer)
          this.lossOdometerValue = this.editClaim.lossOdometer + ' miles';

        this.startNewClaimForm.controls.repairOrderNumber.setValue(
          this.editClaim.repairOrderNumber
        );
        if (this.editClaim.isCarTowed)
          this.startNewClaimForm.controls.isCarTowedIn.setValue('Yes');
        else if (this.editClaim.isCarTowed == false) this.startNewClaimForm.controls.isCarTowedIn.setValue('No');
        else {
          this.startNewClaimForm.controls.isCarTowedIn.setValue('Select');
        }
        this.selectedQuestion1 = this.editClaim.isCarTowed;

        if (this.editClaim.hasModifications)
          this.startNewClaimForm.controls.anyModificationsBoolean.setValue(
            'Yes'
          );
        else if (this.editClaim.hasModifications == false)
          this.startNewClaimForm.controls.anyModificationsBoolean.setValue(
            'No'
          );
        else {
          this.startNewClaimForm.controls.anyModificationsBoolean.setValue('Select');
        }
        this.selectedQuestion2 = this.editClaim.hasModifications;

        if (this.editClaim.hasCommercialUse)
          this.startNewClaimForm.controls.commercialUse.setValue('Yes');
        else if (this.editClaim.hasModifications == false) this.startNewClaimForm.controls.commercialUse.setValue('No');
        else {
          this.startNewClaimForm.controls.commercialUse.setValue('Select');
        }
        this.selectedQuestion3 = this.editClaim.hasCommercialUse;

        if (this.selectedQuestion2)
          this.startNewClaimForm.controls.modificationsDesc.setValue(
            this.editClaim.modificationsDesc
          );
        if (this.selectedQuestion3)
          this.startNewClaimForm.controls.commercialUseDesc.setValue(
            this.editClaim.commercialUseDesc
          );




        this.disableLaborBillingRate = true;
        this.startNewClaimForm.controls.laborBillingRate.disable();


        this.sellerDataSubscription1 = this.claimService.sellerDataReceived.subscribe((value) => {
          if (this.claimService.editClaim && value && !this.sellerDataReceived) {
            this.sellerDataReceived = value;
            this.emails = this.claimService.emailsSeller;
            this.faxes = this.claimService.faxesSeller;
            this.payeeNameLabel = this.claimService.payeeNameLabel;
            this.paymentMethod = this.claimService.paymentMethod;
            this.billingEditable = this.claimService.billingEditable;
            if (!this.editClaim.laborBillingRate) {
              this.laborBillingRateFormatted = this.currencyPipe.transform(
                this.claimService.laborBillingRate,
                '$'
              );
            } else
              if (this.editClaim.laborBillingRate && !this.editClaim.lossDate) {
                this.laborBillingRateFormatted = this.currencyPipe.transform(
                  this.claimService.laborBillingRate,
                  '$'
                );
              }

            if (this.editClaim.billingMethod) {
              this.startNewClaimForm.controls.billingMethod.setValue(
                this.editClaim.billingMethod
              );

            }
            if (this.billingEditable == false) {
              this.startNewClaimForm.controls.billingMethod.disable();
              this.startNewClaimForm.controls.billingMethodValue.disable();
              this.startNewClaimForm.controls.email.disable();
              this.startNewClaimForm.controls.fax.disable();
            } else if (!this.claimService.previewActiveClaim) {
              this.startNewClaimForm.controls.billingMethod.enable();
              this.startNewClaimForm.controls.billingMethodValue.enable();
              this.startNewClaimForm.controls.email.enable();
              this.startNewClaimForm.controls.fax.enable();
            }

            if (this.editClaim.payMethod) {
              this.startNewClaimForm.controls.payMethod.setValue(
                this.editClaim.payMethod
              );
              this.paymentMethod = this.editClaim.payMethod;
            }
            this.startNewClaimForm.controls.payMethod.setValue(this.paymentMethod);

            this.setBillingComunicationValue(this.editClaim.billingMethod, true, this.paymentMethod);
          }
        });

        if (this.editClaim.laborGuide) {
          this.startNewClaimForm.controls.laborGuide.setValue(
            this.editClaim.laborGuide
          );
        }


        if (this.editClaim.laborGuide == 'Other') {
          this.showLaborGuideOtherDesc = true;
          this.startNewClaimForm.controls.laborGuideOther.setValue(
            this.editClaim.laborGuideOther
          );
        }
      });
    }



    this.doubleCheckBillingMethod();



    this.uploadForm = this.formBuilder.group({
      profile: [''],
      uploadFile: [''],
    });
  }
  resetBillingCommunicationFields() {

    this.startNewClaimForm.controls.billingMethodValue.setValue("Select");

    this.startNewClaimForm.controls.billingMethod.setValue("Select");

    this.startNewClaimForm.controls.email.setValue(null);

    this.startNewClaimForm.controls.fax.setValue(null);

    this.startNewClaimForm.controls.billingMethodValue.setErrors(null);
    this.startNewClaimForm.controls.billingMethod.setErrors(null);

    this.startNewClaimForm.controls.email.setErrors(null);
    this.startNewClaimForm.controls.fax.setErrors(null);

    this.hidePaymentsSection = true;
  }

  doubleCheckBillingMethod() {
    let billingMethod: string = this.claimService.billingMethod;

    if (!this.startNewClaimForm.controls.billingMethod.value || this.startNewClaimForm.controls.billingMethod.value == 'Select') {
      if (this.checkIfPaymentMethodIsCreditCard(this.paymentMethod)) {
        this.hidePaymentsSection = false;
        if (billingMethod?.toLowerCase() == "e-mail") {
          this.startNewClaimForm.controls.billingMethod.setValue(
            "E-Mail"
          );
          if (this.billingEditable == false) {
            this.startNewClaimForm.controls.billingMethod.disable();
            this.startNewClaimForm.controls.billingMethodValue.disable();
            this.startNewClaimForm.controls.email.disable();
            this.startNewClaimForm.controls.fax.disable();
          } else if (!this.claimService.previewActiveClaim) {
            this.startNewClaimForm.controls.billingMethod.enable();
            this.startNewClaimForm.controls.billingMethodValue.enable();
            this.startNewClaimForm.controls.email.enable();
            this.startNewClaimForm.controls.fax.enable();
          }
        } else if (billingMethod?.toLowerCase() == 'fax') {
          this.startNewClaimForm.controls.billingMethod.setValue(
            "Fax"
          );
          if (this.billingEditable == false) {
            this.startNewClaimForm.controls.billingMethod.disable();
            this.startNewClaimForm.controls.billingMethodValue.disable();
            this.startNewClaimForm.controls.email.disable();
            this.startNewClaimForm.controls.fax.disable();
          } else if (!this.claimService.previewActiveClaim) {
            this.startNewClaimForm.controls.billingMethod.enable();
            this.startNewClaimForm.controls.billingMethodValue.enable();
            this.startNewClaimForm.controls.email.enable();
            this.startNewClaimForm.controls.fax.enable();
          }

        }

        if (!this.editClaim) {

          this.setBillingComunicationValue(billingMethod, false, this.paymentMethod);

        }
      } else {

        this.resetBillingCommunicationFields();
      }
    }
  }

  setBillingComunicationValue(value, editClaim, paymentMethod) {
    if (this.checkIfPaymentMethodIsCreditCard(paymentMethod)) {
      this.hidePaymentsSection = false;
      if (value?.toLowerCase() == 'fax') {
        let tmpFax;
        if (editClaim) {
          tmpFax = {
            target: {
              value: this.editClaim.fax,
            },
          };
        }
        if (editClaim && this.editClaim?.fax) {
          if (this.checkIfBillingCommunicationArrayContainsInput(this.editClaim.fax, this.faxes)) {
            this.startNewClaimForm.controls.billingMethodValue.setValue(this.editClaim.fax);
          } else {

            this.startNewClaimForm.controls.billingMethodValue.setValue("OtherFax");
            this.transformFax(tmpFax);
          }
        } else if (!editClaim) {
          let defaultFax = this.lookForDefaultValue(this.faxes);
          if (defaultFax) {
            this.startNewClaimForm.controls.billingMethodValue.setValue(defaultFax?.value);
          }
          else {

            this.startNewClaimForm.controls.billingMethodValue.setValue("Select");
          }
        } else {
          this.startNewClaimForm.controls.billingMethodValue.setValue("Select");
        }
      }
      else if (value?.toLowerCase() == 'e-mail') {
        if (editClaim && this.editClaim.email) {
          if (this.checkIfBillingCommunicationArrayContainsInput(this.editClaim.email, this.emails)) {
            this.startNewClaimForm.controls.billingMethodValue.setValue(this.editClaim.email);
          } else {

            this.startNewClaimForm.controls.billingMethodValue.setValue("OtherEmail");
            this.startNewClaimForm.controls.email.setValue(this.editClaim.email);
          }
        } else if (!editClaim) {
          let defaultEmail = this.lookForDefaultValue(this.emails);
          if (defaultEmail) {
            this.startNewClaimForm.controls.billingMethodValue.setValue(defaultEmail?.value);
          }
          else {
            this.startNewClaimForm.controls.billingMethodValue.setValue("Select");
          }
        }
        else {

          this.startNewClaimForm.controls.billingMethodValue.setValue("Select");
        }
      } else {
        this.startNewClaimForm.controls.billingMethodValue.setValue("Select");
        this.startNewClaimForm.controls.billingMethod.setValue("Select");

      }
    } else {

      this.resetBillingCommunicationFields();
    }
  }
  checkIfBillingCommunicationArrayContainsInput(value: string, array: any[]) {
    if (!value || !array) return false;
    for (let i = 0; i < array?.length; i++) {
      if (value == array[i].value) {
        return true;
      }
    }
    return false;
  }
  lookForDefaultValue(array: any[]) {
    if (!array) return;
    for (let i = 0; i < array?.length; i++) {
      if (array[i].isDefault) {
        return array[i];
      }
    }
    return;
  }
  ngOnDestroy() {
    this.contractSubscription?.unsubscribe();
    this.contractsSubscription?.unsubscribe();
    this.claimSubscription?.unsubscribe();
    this.sellerDataSubscription?.unsubscribe();
    this.sellerDataSubscription1?.unsubscribe();
  }
  selectYesNo(value, question) {
    if (value == 'Yes' && question == '1') {
      this.selectedQuestion1 = true;

    } else if (value !== 'Yes' && question == '1') {
      this.selectedQuestion1 = false;
      this.toastr.clear();
    } else if (value == 'Yes' && question == '2') {
      this.selectedQuestion2 = true;
    } else if (value !== 'Yes' && question == '2') {
      this.selectedQuestion2 = false;
    } else if (value == 'Yes' && question == '3') {
      this.selectedQuestion3 = true;

    } else if (value !== 'Yes' && question == '3') {
      this.selectedQuestion3 = false;
      this.toastr.clear();
    }
  }
  goToNextPage() {
    this.messageEvent.emit(true);
  }
  get f() {
    return this.startNewClaimForm.controls;
  }

  blurLossDate() {
    if (
      !this.startNewClaimForm.controls.lossDate.value ||
      this.startNewClaimForm.controls.lossDate.value.length == 0 ||
      new Date(this.startNewClaimForm.controls.lossDate.value).getTime() >
      new Date(this.lossDateMax)?.getTime() ||
      new Date(this.startNewClaimForm.controls.lossDate.value).getTime() <
      new Date(this.lossDateMin)?.getTime()
    )
      this.lossDateIncorrectError = true;
    else {
      this.lossDateIncorrectError = false;
      this.getLaborInfoByLossDate();
    }
  }

  getLaborInfoByLossDate() {
    let contractInfo = {
      contractNumber: this.contract?.contractNumber,
      lastName: this.contract?.customer?.lastName,
      vin: this.contract?.vehicle?.vIN
    };
    this.ratesCheck = true;
    let lossDate;
    if (this.startNewClaimForm.controls.lossDate.value) {
      lossDate = this.startNewClaimForm.controls.lossDate.value;
    } else {
      lossDate = this.datepipe.transform(
        new Date(),
        'yyyy-MM-dd'
      );
    }

    this.claimService.getLaborRateByLossDate(lossDate, contractInfo, this.claimService.accountNumber).subscribe((result: any) => {
      if (result) {
        if (result.laborTax || result.laborTax == 0)
          this.laborTax = result.laborTax;
        if (result.partTax || result.partTax == 0)
          this.partTax = result.partTax;

        this.partMarkup = result.partMarkup;


        if (result.laborRate) {
          this.laborBillingRateFormatted = this.currencyPipe.transform(
            result.laborRate,
            '$'
          );
          this.formattedAmount = this.laborBillingRateFormatted;
        } else {
          this.laborBillingRateFormatted = this.currencyPipe.transform(
            this.claimService.laborBillingRate,
            '$'
          );
          this.formattedAmount = this.laborBillingRateFormatted;
        }
        this.ratesCheck = false;
      }

    }, (error) => {
      console.log(error);
      this.ratesCheck = true;
      this.toastr.error(
        'Something went wrong while getting labor info, please try again. ' +
        'Error code: ' +
        error.status,
        'Create claim',
        {
          positionClass: 'toast-top-center',
        }
      );
    });

  }

  setLossDateRelatedFieldsByDefault() {
    if (!this.editClaim) {
      this.laborBillingRateFormatted = this.currencyPipe.transform(
        this.claimService.laborBillingRate,
        '$'
      );
    }
    this.laborTax = this.claimService.laborTax;
    this.partTax = this.claimService.partTax;
    this.partMarkup = this.claimService.partMarkup;
    this.ratesCheck = false;
  }

  checkIfPaymentMethodIsCreditCard(paymentMethod: string) {
    if (paymentMethod?.toLowerCase() == "credit card") {
      return true;
    }
    return false;
  }

  submitForm(stayOnPage: boolean) {

    this.validateFields();
    if (this.claimService.previewActiveClaim) {
      this.goToNextPage();
    }

    if (this.LOCK_CALLS_UPDATE || this.LOCK_CALLS_UNLOCK) {
      return;
    }
    this.LOCK_CALLS_UPDATE = true;

    this.showSpinner = true;
    this.submitted = true;

    setTimeout(() => {
      this.submitted = true;
      this.showSpinner = true;
      this.lossDateEmptyError = false;
      this.lossOdometerEmptyError = false;
      this.saveAsDraftPressed = false;

      if ((!this.startNewClaimForm.controls.billingMethodValue.value || this.startNewClaimForm.controls.billingMethodValue.value == "Select")) {
        this.startNewClaimForm.controls.billingMethodValue.setErrors({
          required: true
        });

      }
      if (
        !this.startNewClaimForm.valid ||
        this.billingRateAmountError ||
        this.faxError ||
        this.odometerError ||
        this.lossDateIncorrectError ||
        this.showRoError ||
        this.startNewClaimForm.controls.isCarTowedIn.value == 'Select' ||
        this.startNewClaimForm.controls.anyModificationsBoolean.value == 'Select' ||
        this.startNewClaimForm.controls.commercialUse.value == 'Select'
      ) {
        if (
          !this.startNewClaimForm.controls.lossDate.value ||
          this.startNewClaimForm.controls.lossDate.value.length == 0
        )
          this.lossDateEmptyError = true;

        if (
          !this.startNewClaimForm.controls.lossOdometer.value ||
          this.startNewClaimForm.controls.lossDate.value?.length == 0
        )
          this.lossOdometerEmptyError = true;

        if (!this.selectedQuestion2)
          this.startNewClaimForm.controls.modificationsDesc.setErrors(null);
        if (!this.selectedQuestion3)
          this.startNewClaimForm.controls.commercialUseDesc.setErrors(null);
        if (
          this.startNewClaimForm.controls.payMethod.value == 'Credit Card' &&
          this.startNewClaimForm.controls.billingMethod.value
        ) {
          if (this.startNewClaimForm.controls.billingMethod.value == 'E-Mail') {
            this.startNewClaimForm.controls.fax.setErrors(null);
            this.faxError = false;
            if (this.startNewClaimForm.controls.billingMethodValue.value != "OtherEmail") {
              this.startNewClaimForm.controls.email.setErrors(null);
            }
          } else if (this.startNewClaimForm.controls.billingMethod.value == 'Fax') {
            this.startNewClaimForm.controls.email.setErrors(null);
            if (this.startNewClaimForm.controls.billingMethodValue.value != "OtherFax") {
              this.startNewClaimForm.controls.fax.setErrors(null);
              this.faxError = false;
            }
          }
        }
        if (this.startNewClaimForm.controls.payMethod.value == 'Check' || this.startNewClaimForm.controls.payMethod.value == 'Net Remit') {
          this.startNewClaimForm.controls.fax.setErrors(null);
          this.faxError = false;
          this.startNewClaimForm.controls.email.setErrors(null);
          this.startNewClaimForm.controls.billingMethod.setErrors(null);
          this.startNewClaimForm.controls.billingMethodValue.setErrors(null);
        }

        if (!this.showLaborGuideOtherDesc)
          this.startNewClaimForm.controls.laborGuideOther.setErrors(null);

        if (
          !this.startNewClaimForm.valid ||
          this.billingRateAmountError ||
          this.faxError ||
          this.odometerError ||
          this.lossDateIncorrectError ||
          this.showRoError ||
          this.startNewClaimForm.controls.isCarTowedIn.value == 'Select' ||
          this.startNewClaimForm.controls.anyModificationsBoolean.value == 'Select' ||
          this.startNewClaimForm.controls.commercialUse.value == 'Select'
        ) {
          if (this.startNewClaimForm.controls.isCarTowedIn.value == 'Select') {
            this.startNewClaimForm.controls.isCarTowedIn.setErrors({ required: true });
          }
          if (this.startNewClaimForm.controls.anyModificationsBoolean.value == 'Select') {
            this.startNewClaimForm.controls.anyModificationsBoolean.setErrors({ required: true });
          }
          if (this.startNewClaimForm.controls.commercialUse.value == 'Select') {
            this.startNewClaimForm.controls.commercialUse.setErrors({ required: true });
          }

          this.showSpinner = false;
          this.LOCK_CALLS_UPDATE = false;
          this.LOCK_CALLS_UNLOCK = false;
          return;
        }
      }
      if ((!this.initialClaimExist || (this.initialClaimExist && this.roNumberFromExistingClaim && this.startNewClaimForm.controls.repairOrderNumber.value
        && this.roNumberFromExistingClaim != this.startNewClaimForm.controls.repairOrderNumber.value)) && this.startNewClaimForm.controls.repairOrderNumber.value) {
        this.showRoError = false;

        this.claimService.checkRepairOrderNumber(this.startNewClaimForm.controls.repairOrderNumber.value, this.contract?.contractNumber, this.initialClaimId).subscribe((
          result: any) => {
          for (let i = 0; i < result?.length; i++) {
            if (result[i]) {
              this.showRoError = true;
              this.roErrorMessage = result[i];
            }
          }
          if (this.showRoError) {
            this.showSpinner = false;
            this.LOCK_CALLS_UPDATE = false;
            this.LOCK_CALLS_UNLOCK = false;
            return;
          } else {
            this.submitClaimCall(stayOnPage);
          }
        },
          (error) => {
            console.log(error);
            this.showSpinner = false;
            this.LOCK_CALLS_UPDATE = false;
            this.LOCK_CALLS_UNLOCK = false;

            this.toastr.error(
              'Something went wrong while checking the Repair Order Number, please try again. ' +
              'Error code: ' +
              error.status,
              'Create claim',
              {
                positionClass: 'toast-top-center',
              }
            );
          }
        );
      } else {
        this.submitClaimCall(stayOnPage);
      }
    }, 800);
  }
  submitClaimCall(stayOnPage: boolean) {
    this.submitted = false;
    const searchCurrency = '$';
    const replaceWith = '';
    const searchComma = ',';
    let amountTmp = this.formattedAmount
      ?.split(searchCurrency)
      .join(replaceWith)
      .split(searchComma)
      .join(replaceWith);

    let odometerTmp = this.lossOdometerValue
      ?.replace('miles', '')
      .replace(' ', '');
    let date = new Date(this.startNewClaimForm.controls.lossDate.value);
    let momentDate = moment.tz(date, 'UTC');

    let claimObj: Claim = {
      lossDate: momentDate.tz('UTC').format(),
      lossOdometer: +odometerTmp,
      id: '',
      step: 1,
      repairOrderNumber:
        this.startNewClaimForm.controls.repairOrderNumber.value,
      hasCommercialUse: this.selectedQuestion3,
      hasModifications: this.selectedQuestion2,
      isCarTowed: this.selectedQuestion1,
      payMethod: this.startNewClaimForm.controls.payMethod.value,
      laborBillingRate: +amountTmp,
      email: this.startNewClaimForm.controls.email.value,
      fax: this.startNewClaimForm.controls.fax.value,
      laborGuide: this.startNewClaimForm.controls.laborGuide.value,
      laborGuideOther: this.startNewClaimForm.controls.laborGuideOther.value,
    };
    if (this.startNewClaimForm.controls.isCarTowedIn.value != 'Yes' && this.startNewClaimForm.controls.isCarTowedIn.value != 'No') {
      claimObj.isCarTowed = null;
    }
    if (this.startNewClaimForm.controls.anyModificationsBoolean.value != 'Yes' && this.startNewClaimForm.controls.anyModificationsBoolean.value != 'No') {
      claimObj.hasModifications = null;
    }
    if (this.startNewClaimForm.controls.commercialUse.value != 'Yes' && this.startNewClaimForm.controls.commercialUse.value != 'No') {
      claimObj.hasCommercialUse = null;
    }
    if (this.startNewClaimForm.controls.payMethod.value == 'Credit Card') {
      claimObj.billingMethod =
        this.startNewClaimForm.controls.billingMethod.value;
      if (this.startNewClaimForm.controls.billingMethod.value == 'E-Mail') {
        claimObj.fax = '';
      } else if (this.startNewClaimForm.controls.billingMethod.value == 'Fax') {
        claimObj.email = '';
        let newVal = this.startNewClaimForm.controls.fax.value?.replace(
          /\D/g,
          ''
        );
        claimObj.fax = newVal;
      }
    }
    if (this.startNewClaimForm.controls.billingMethod.value == 'E-Mail' && this.startNewClaimForm.controls.billingMethodValue.value != "OtherEmail") {
      claimObj.email = this.startNewClaimForm.controls.billingMethodValue.value;
    }
    if (this.startNewClaimForm.controls.billingMethod.value == 'Fax' && this.startNewClaimForm.controls.billingMethodValue.value != "OtherFax") {
      claimObj.fax = this.startNewClaimForm.controls.billingMethodValue.value;
    }

    if (this.selectedQuestion2)
      claimObj.modificationsDesc =
        this.startNewClaimForm.controls.modificationsDesc.value;
    if (this.selectedQuestion3)
      claimObj.commercialUseDesc =
        this.startNewClaimForm.controls.commercialUseDesc.value;

    if (!this.editClaim) {
      claimObj.vin = this.contract.vehicle.vIN;
    } else {
      claimObj.step = this.editClaim.step;
      if (this.editClaim.step == 0) claimObj.step = 1;
    }

    if (this.initialClaimExist) {
      claimObj.id = this.initialClaimId;
      this.updateClaim(claimObj, !stayOnPage, this.saveAsDraftPressed);
    } else {
      let initialClaimInfo: Claim = {
        accountNumber: this.claimService.accountNumber,
        email: this.claimService.email,
        contractNumber: this.contract.contractNumber,
        step: 0,
        vin: this.contract.vehicle.vIN,
        userId: this.claimService.userId,
        userFullName: this.claimService.userName,
      };
      this.claimService.createClaim(initialClaimInfo).subscribe(
        (result: any) => {
          claimObj.id = result.id;
          this.initialClaimExist = true;
          this.claimService.claim.next(result);

          this.updateClaim(claimObj, !stayOnPage, this.saveAsDraftPressed);
        },
        (error) => {
          this.LOCK_CALLS_UPDATE = false;
          this.LOCK_CALLS_UNLOCK = false;

          console.log(error);
          this.toastr.error(
            'Something went wrong while creating claim, please try again. ' +
            'Error code: ' +
            error.status,
            'Create claim',
            {
              positionClass: 'toast-top-center',
            }
          );
        }
      );
    }
  }
  updateClaim(claim, nextPage, saveAsDraftPressed) {
    this.claimService.updateClaim(claim).subscribe(
      (result: any) => {
        this.showSpinner = false;
        this.showSpinnerDraft = false;
        this.claimService.startClaimFromSearch = false;


        if (!saveAsDraftPressed) {
          this.toastr.success(
            'Successfully saved claim',
            'Create claim',
            {
              positionClass: 'toast-top-center',
            }
          );

        }
        if (nextPage) {
          if (this.initialClaimExist && this.editClaim && !saveAsDraftPressed)
            this.toastr.success(
              'Successfully Updated Claim Informations',
              'Create claim',
              {
                positionClass: 'toast-top-center',
              }
            );
          else if (!saveAsDraftPressed)
            this.toastr.success(
              'Successfully Completed Step 1. Claim Information',
              'Create claim',
              {
                positionClass: 'toast-top-center',
              }
            );
          if (!saveAsDraftPressed && result.step > 0) {
            this.contractSubscription?.unsubscribe();
            this.contractsSubscription?.unsubscribe();
            this.claimSubscription?.unsubscribe();
            this.sellerDataSubscription?.unsubscribe();
            this.sellerDataSubscription1?.unsubscribe();
            this.claimService.claim.next(result);
            setTimeout(() => {
              this.goToNextPage();
            }, 0);
          }
        }
        setTimeout(() => {
          this.claimService.claim.next(result);
        }, 0);
        this.LOCK_CALLS_UPDATE = false;
        this.saveAsDraftPressed = false;
      },
      (error) => {
        console.log(error);
        if (error.status && error.status == 403) {
          this.toastr.error(
            'The claim is being edited by another user, please try again later.',
            'Create claim',
            {
              positionClass: 'toast-top-center',
            }
          );
        } else {
          this.toastr.error(
            'Something went wrong while creating claim, please try again',
            'Create claim',
            {
              positionClass: 'toast-top-center',
            }
          );
        }
        this.showSpinner = false;
        this.showSpinnerDraft = false;
        this.saveAsDraftPressed = false;
        this.LOCK_CALLS_UPDATE = false;
      }
    );
  }
  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.uploadForm.get('profile').setValue(file);
    }
  }
  onSubmit() {
    this.uploadForm.controls.uploadFile.setValue('');
    const formData = new FormData();
    formData.append('file', this.uploadForm.get('profile').value);
    if (!this.initialClaimExist) {
      let initialClaimInfo: Claim = {
        accountNumber: this.claimService.accountNumber,
        email: this.claimService.email,
        contractNumber: this.contract.contractNumber,
        step: 0,
        vin: this.contract.vehicle.vIN,
      };
      this.claimService.createClaim(initialClaimInfo).subscribe(
        (result: any) => {
          this.initialClaimId = result.id;
          this.claimService.claim.next(result);
          this.addAttachment(this.initialClaimId, formData);
          this.initialClaimExist = true;
        },
        (error) => {
          console.log(error);
          this.toastr.error(
            'Something went wrong while creating new claim, please try again. ' +
            'Error code: ' +
            error.status,
            'Create claim',
            {
              positionClass: 'toast-top-center',
              timeOut: 12000,
            }
          );
        }
      );
    } else {
      this.addAttachment(this.initialClaimId, formData);
    }
  }
  addAttachment(claimId, formData) {
    this.claimService.addAttachment(claimId, formData).subscribe(
      () => {
        this.getAttachments();
        this.uploadForm.controls.uploadFile.setValue('');
        this.toastr.success('Successfully added attachment', 'Create claim', {
          positionClass: 'toast-top-center',
        });
      },
      (error) => {
        console.log(error);
        this.toastr.error(
          'Something went wrong while adding attachment, please try again. ' +
          'Error code: ' +
          error.status,
          'Create claim',
          {
            positionClass: 'toast-top-center',
          }
        );
      }
    );
  }
  getAttachments() {
    if (this.initialClaimId) {
      this.claimService.getAttachments(this.initialClaimId).subscribe(
        (result: any) => {
          this.attachments = result;
        },
        (error) => console.log(error)
      );
    }
  }
  deleteAttachment(attachment) {
    this.claimService.deleteAttachment(attachment.fileId).subscribe(
      () => {
        this.getAttachments();
        this.toastr.success('Successfully deleted attachment', 'Create claim', {
          positionClass: 'toast-top-center',
        });
      },
      (error) => {
        console.log(error);
        this.toastr.error(
          'Something went wrong while deleting attachment, please try again',
          'Create claim',
          {
            positionClass: 'toast-top-center',
          }
        );
      }
    );
  }
  downloadAttachment(attachment) {
    this.claimService.downloadAttachment(attachment).subscribe(
      (result: any) => {
        const blob = new Blob([result], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      },
      (error) => {
        console.log(error);

        window.open(error.url);
      }
    );
  }
  showLaborGuideOtherDescField() {
    if (this.startNewClaimForm.controls.laborGuide.value == 'Other')
      this.showLaborGuideOtherDesc = true;
    else this.showLaborGuideOtherDesc = false;
  }
  emitRepairOrderNumber() {
    this.repairOrderNumberEvent.emit(
      this.startNewClaimForm.controls.repairOrderNumber.value
    );
  }
  checkRepairOrderNumber() {
    this.showRoError = false;

    if (this.startNewClaimForm.controls.repairOrderNumber.value && !this.claimService.startClaimFromSearch) {
      this.roCheckSpinner = true;
      this.claimService.checkRepairOrderNumber(this.startNewClaimForm.controls.repairOrderNumber.value, this.contract?.contractNumber, this.initialClaimId).subscribe((
        result: any) => {
        for (let i = 0; i < result?.length; i++) {
          if (result[i]) {
            this.showRoError = true;
            this.roErrorMessage = result[i];
          }
        }

        if (!this.showRoError) {
          this.emitRepairOrderNumber();
          this.saveAsDraft(true);
          this.lastValidRONum = this.startNewClaimForm.controls.repairOrderNumber.value;
        }
        this.roCheckSpinner = false;
      },
        (error) => {
          console.log(error);
          this.roCheckSpinner = false;
          this.toastr.error(
            'Something went wrong while checking the Repair Order Number, please try again. ' +
            'Error code: ' +
            error.status,
            'Create claim',
            {
              positionClass: 'toast-top-center',
            }
          );
        }
      );
    } else if (this.startNewClaimForm.controls.repairOrderNumber.value && this.claimService.startClaimFromSearch) {
      this.saveAsDraft(true);
    } else {
      this.showRoError = false;
      this.roCheckSpinner = false;
    }
  }
  deleteClaim($event) {
    if ($event && !this.claimService.startClaimFromSearch) {
      this.claimService.deleteClaim(this.claimService.claim.value.id).subscribe(
        () => {
          document.getElementById('closeConfirmActionButton0').click();
          this.toastr.success('Successfully deleted claim', '', {
            positionClass: 'toast-top-center',
          });
          this.router.navigate(['']);
        },
        (error) => {
          console.log(error);
          if (error.status && error.status == 403) {
            this.toastr.error(
              'The claim is being edited by another user, please try again later.',
              'Create claim',
              {
                positionClass: 'toast-top-center',
              }
            );
          } else {
            this.toastr.error(
              'Something went wrong while deleting claim, please try again. ' +
              'Error code: ' +
              error.status,
              'Create claim',
              {
                positionClass: 'toast-top-center',
              }
            );
          }
        }
      );
    } else if ($event && this.claimService.startClaimFromSearch) {
      document.getElementById('closeConfirmActionButton0').click();
      this.toastr.success('Successfully deleted claim', '', {
        positionClass: 'toast-top-center',
      });
      this.router.navigate(['']);
    }
  }
  setClaimDelete() {
    this.message = 'Are you sure you want to delete this claim?';
  }

  billingMethodChanged(value) {
    if (!value) return;

    if (this.editClaim && this.editClaim.payMethod) {
      this.setBillingComunicationValue(value, false, this.editClaim.payMethod);
    }
    else {
      this.setBillingComunicationValue(value, false, this.paymentMethod);
    }
  }


  validateFields() {
    this.startNewClaimForm.controls.lossDate.updateValueAndValidity();
    this.startNewClaimForm.controls.lossOdometer.updateValueAndValidity();
    this.startNewClaimForm.controls.modificationsDesc.updateValueAndValidity();
    this.startNewClaimForm.controls.commercialUseDesc.updateValueAndValidity();
    this.startNewClaimForm.controls.anyModificationsBoolean.updateValueAndValidity();
    this.startNewClaimForm.controls.isCarTowedIn.updateValueAndValidity();
    this.startNewClaimForm.controls.commercialUse.updateValueAndValidity();
    this.startNewClaimForm.controls.billingMethod.updateValueAndValidity();
    this.startNewClaimForm.controls.payMethod.updateValueAndValidity();
    this.startNewClaimForm.controls.laborBillingRate.updateValueAndValidity();
    this.startNewClaimForm.controls.laborGuide.updateValueAndValidity();
    this.startNewClaimForm.controls.laborGuideOther.updateValueAndValidity();
    this.startNewClaimForm.controls.payMethod.updateValueAndValidity();
  }
  saveAsDraft(_fromCode: boolean) {
    if (this.saveAsDraftPressed) {
      return;
    }

    if (this.editClaim && this.editClaim.step > 0) {
      this.submitForm(true);
      return;
    }

    if (this.LOCK_CALLS_UPDATE || this.LOCK_CALLS_UNLOCK) {
      return;
    }

    this.LOCK_CALLS_UPDATE = true;
    this.LOCK_CALLS_UNLOCK = true;

    this.saveAsDraftPressed = true;
    this.submitted = true;
    this.showSpinnerDraft = true;
    this.lossDateEmptyError = false;
    this.lossOdometerEmptyError = false;

    this.startNewClaimForm.controls.lossDate.setErrors(null);
    this.startNewClaimForm.controls.lossOdometer.setErrors(null);
    this.startNewClaimForm.controls.modificationsDesc.setErrors(null);
    this.startNewClaimForm.controls.commercialUseDesc.setErrors(null);
    this.startNewClaimForm.controls.anyModificationsBoolean.setErrors(null);
    this.startNewClaimForm.controls.isCarTowedIn.setErrors(null);
    this.startNewClaimForm.controls.commercialUse.setErrors(null);
    this.startNewClaimForm.controls.billingMethod.setErrors(null);
    this.startNewClaimForm.controls.payMethod.setErrors(null);
    this.startNewClaimForm.controls.fax.setErrors(null);
    this.startNewClaimForm.controls.laborBillingRate.setErrors(null);
    this.startNewClaimForm.controls.laborGuide.setErrors(null);
    this.startNewClaimForm.controls.laborGuideOther.setErrors(null);

    if (
      this.startNewClaimForm.controls.payMethod.value == 'Credit Card' &&
      this.startNewClaimForm.controls.billingMethod.value
    ) {
      if (this.startNewClaimForm.controls.billingMethod.value == 'E-Mail') {
        this.startNewClaimForm.controls.fax.setErrors(null);
        this.faxError = false;

        if (this.startNewClaimForm.controls.billingMethodValue.value != "Select" && this.startNewClaimForm.controls.billingMethodValue.value != "OtherEmail") {
          this.startNewClaimForm.controls.email.setErrors(null);
        }
        else if (this.startNewClaimForm.controls.billingMethodValue.value == "Select") {
          this.startNewClaimForm.controls.email.setErrors(null);
        }
      } else if (this.startNewClaimForm.controls.billingMethod.value == 'Fax') {
        this.startNewClaimForm.controls.email.setErrors(null);
        if (this.startNewClaimForm.controls.billingMethodValue.value != "Select" && this.startNewClaimForm.controls.billingMethodValue.value != "OtherFax") {
          this.faxError = false;
        }
      }
    }
    if ((!this.startNewClaimForm.controls.billingMethodValue.value || this.startNewClaimForm.controls.billingMethodValue.value == "Select")) {
      this.startNewClaimForm.controls.billingMethodValue.setErrors(null);
      this.faxError = false;
      this.startNewClaimForm.controls.fax.setErrors(null);
      this.startNewClaimForm.controls.email.setErrors(null);
    }
    if (
      !this.startNewClaimForm.valid ||
      this.billingRateAmountError ||
      this.faxError ||
      this.odometerError ||
      this.lossDateIncorrectError ||
      this.showRoError
    ) {
      if (
        !this.startNewClaimForm.controls.lossDate.value ||
        this.startNewClaimForm.controls.lossDate.value.length == 0
      )
        this.lossDateEmptyError = true;

      if (
        !this.startNewClaimForm.controls.lossOdometer.value ||
        this.startNewClaimForm.controls.lossDate.value?.length == 0
      )
        this.lossOdometerEmptyError = true;

      if (!this.selectedQuestion2)
        this.startNewClaimForm.controls.modificationsDesc.setErrors(null);
      if (!this.selectedQuestion3)
        this.startNewClaimForm.controls.commercialUseDesc.setErrors(null);

      if (this.startNewClaimForm.controls.payMethod.value == 'Check' || this.startNewClaimForm.controls.payMethod.value == 'Net Remit') {
        this.startNewClaimForm.controls.fax.setErrors(null);
        this.faxError = false;
        this.startNewClaimForm.controls.email.setErrors(null);
        this.startNewClaimForm.controls.billingMethod.setErrors(null);
        this.startNewClaimForm.controls.billingMethodValue.setErrors(null);
      }

      if (!this.showLaborGuideOtherDesc)
        this.startNewClaimForm.controls.laborGuideOther.setErrors(null);

      if (
        !this.startNewClaimForm.valid ||
        this.billingRateAmountError ||
        this.faxError ||
        this.odometerError ||
        this.lossDateIncorrectError ||
        this.showRoError
      ) {
        this.showSpinnerDraft = false;
        this.saveAsDraftPressed = false;
        this.LOCK_CALLS_UPDATE = false;
        this.LOCK_CALLS_UNLOCK = false;

        return;
      }
    }



    if ((!this.initialClaimExist || (this.initialClaimExist && this.roNumberFromExistingClaim && this.startNewClaimForm.controls.repairOrderNumber.value
      && this.roNumberFromExistingClaim != this.startNewClaimForm.controls.repairOrderNumber.value)) && this.startNewClaimForm.controls.repairOrderNumber.value ||
      (this.lastValidRONum && this.startNewClaimForm.controls.repairOrderNumber?.value == this.lastValidRONum)) {
      this.showRoError = false;
      this.claimService.checkRepairOrderNumber(this.startNewClaimForm.controls.repairOrderNumber.value, this.contract?.contractNumber, this.initialClaimId).subscribe((
        result: any) => {

        for (let i = 0; i < result?.length; i++) {
          if (result[i]) {
            this.showRoError = true;
            this.roErrorMessage = result[i];
          }
        }
        if (this.showRoError) {
          this.showSpinnerDraft = false;
          this.saveAsDraftPressed = false;
          this.LOCK_CALLS_UPDATE = false;
          this.LOCK_CALLS_UNLOCK = false;

          return;
        } else {
          this.saveAsDraftCall();
        }
      },
        (error) => {
          this.showSpinnerDraft = false;

          this.saveAsDraftPressed = false;
          this.LOCK_CALLS_UPDATE = false;
          this.LOCK_CALLS_UNLOCK = false;

          console.log(error);
          this.toastr.error(
            'Something went wrong while checking the Repair Order Number, please try again. ' +
            'Error code: ' +
            error.status,
            'Create claim',
            {
              positionClass: 'toast-top-center',
            }
          );
        }
      );
    } else {
      this.saveAsDraftCall();
    }
  }
  saveAsDraftCall() {

    this.emitRepairOrderNumber();
    this.submitted = false;
    const searchCurrency = '$';
    const replaceWith = '';
    const searchComma = ',';
    let amountTmp;
    if (this.formattedAmount)
      amountTmp = this.formattedAmount
        .split(searchCurrency)
        .join(replaceWith)
        .split(searchComma)
        .join(replaceWith);

    let odometerTmp = this.lossOdometerValue
      ?.replace('miles', '')
      .replace(' ', '');

    let momentDate;
    if (this.startNewClaimForm.controls.lossDate.value) {
      let date = new Date(this.startNewClaimForm.controls.lossDate.value);
      momentDate = moment.tz(date, 'UTC');
    }

    let claimObj: Claim = {
      lossDate: momentDate?.tz('UTC').format(),
      lossOdometer: +odometerTmp,
      id: '',
      step: 0,
      repairOrderNumber:
        this.startNewClaimForm.controls.repairOrderNumber.value,
      hasCommercialUse: this.selectedQuestion3,
      hasModifications: this.selectedQuestion2,
      isCarTowed: this.selectedQuestion1,
      payMethod: this.startNewClaimForm.controls.payMethod.value,
      laborBillingRate: +amountTmp,
      email: this.startNewClaimForm.controls.email.value,
      fax: this.startNewClaimForm.controls.fax.value,
      laborGuide: this.startNewClaimForm.controls.laborGuide.value,
      laborGuideOther: this.startNewClaimForm.controls.laborGuideOther.value,
    };
    if (this.startNewClaimForm.controls.isCarTowedIn.value != 'Yes' && this.startNewClaimForm.controls.isCarTowedIn.value != 'No') {
      claimObj.isCarTowed = null;
    }
    if (this.startNewClaimForm.controls.anyModificationsBoolean.value != 'Yes' && this.startNewClaimForm.controls.anyModificationsBoolean.value != 'No') {
      claimObj.hasModifications = null;
    }
    if (this.startNewClaimForm.controls.commercialUse.value != 'Yes' && this.startNewClaimForm.controls.commercialUse.value != 'No') {
      claimObj.hasCommercialUse = null;
    }
    if (this.startNewClaimForm.controls.payMethod.value == 'Credit Card') {
      claimObj.billingMethod =
        this.startNewClaimForm.controls.billingMethod.value;
      if (this.startNewClaimForm.controls.billingMethod.value && this.startNewClaimForm.controls.billingMethod.value == "Select") {
        claimObj.billingMethod = "";
      }
      if (this.startNewClaimForm.controls.billingMethod.value == 'E-Mail') {
        claimObj.fax = '';
        if (this.startNewClaimForm.controls.billingMethodValue.value == "OtherEmail") {
          if (!this.startNewClaimForm.controls.email.errors && this.startNewClaimForm.controls.email.value) {
            claimObj.email = this.startNewClaimForm.controls.email.value;
          } else {
            this.showSpinnerDraft = false;
            this.startNewClaimForm.controls.email.setErrors({ required: true });
            this.saveAsDraftPressed = false;
            this.LOCK_CALLS_UPDATE = false;
            this.LOCK_CALLS_UNLOCK = false;

            return;
          }
        }
      } else if (this.startNewClaimForm.controls.billingMethod.value == 'Fax') {
        if (this.startNewClaimForm.controls.billingMethodValue.value == "OtherFax") {
          if (!this.startNewClaimForm.controls.fax.errors && this.startNewClaimForm.controls.fax.value) {
            claimObj.email = '';
            this.faxError = false;
            let newVal = this.startNewClaimForm.controls.fax.value?.replace(
              /\D/g,
              ''
            );
            claimObj.fax = newVal;
          } else {
            this.showSpinnerDraft = false;
            this.faxError = true;
            this.saveAsDraftPressed = false;
            this.LOCK_CALLS_UPDATE = false;
            this.LOCK_CALLS_UNLOCK = false;

            return;
          }
        }
      }
    }
    if (this.startNewClaimForm.controls.billingMethod.value == 'E-Mail' && this.startNewClaimForm.controls.billingMethodValue.value != "OtherEmail") {
      claimObj.email = this.startNewClaimForm.controls.billingMethodValue.value;
    }
    if (this.startNewClaimForm.controls.billingMethod.value == 'Fax' && this.startNewClaimForm.controls.billingMethodValue.value != "OtherFax") {
      claimObj.fax = this.startNewClaimForm.controls.billingMethodValue.value;
    }

    if (this.startNewClaimForm.controls.billingMethodValue.value == "Select") {
      claimObj.fax = "";
      claimObj.email = "";
      if (!this.startNewClaimForm.controls.billingMethod.value || this.startNewClaimForm.controls.billingMethod.value == "Select") {
        claimObj.billingMethod = "";
      }
    }
    if (this.selectedQuestion2)
      claimObj.modificationsDesc =
        this.startNewClaimForm.controls.modificationsDesc.value;
    if (this.selectedQuestion3)
      claimObj.commercialUseDesc =
        this.startNewClaimForm.controls.commercialUseDesc.value;

    if (!this.editClaim) {
      claimObj.vin = this.contract.vehicle.vIN;
    } else {
      claimObj.step = this.editClaim.step;
    }

    if (this.initialClaimExist) {
      claimObj.id = this.initialClaimId;
      this.updateClaim(claimObj, true, this.saveAsDraftPressed);
      this.unlockClaim(claimObj.id);
    } else {
      let initialClaimInfo: Claim = {
        accountNumber: this.claimService.accountNumber,
        email: this.claimService.email,
        contractNumber: this.contract.contractNumber,
        step: 0,
        vin: this.contract.vehicle.vIN,
        userId: this.claimService.userId,
        userFullName: this.claimService.userName,
      };
      this.claimService.createClaim(initialClaimInfo).subscribe(
        (result: any) => {
          claimObj.id = result.id;
          this.initialClaimExist = true;
          this.initialClaimId = result.id;
          this.claimService.claim.next(result);

          this.updateClaim(claimObj, true, this.saveAsDraftPressed);
          this.unlockClaim(claimObj.id);
        },
        (error) => {
          this.showSpinnerDraft = false;
          this.LOCK_CALLS_UPDATE = false;
          this.LOCK_CALLS_UNLOCK = false;


          console.log(error);
          this.toastr.error(
            'Something went wrong while saving claim as draft, please try again. ' +
            'Error code: ' +
            error.status,
            'Create claim',
            {
              positionClass: 'toast-top-center',
            }
          );
        }
      );
    }

  }

  unlockClaim(claimId) {
    this.claimService.unlockClaim(claimId).subscribe(
      () => {
        if (!this.saveAsDraftPressed)

          this.saveAsDraftPressed = false;
        this.LOCK_CALLS_UNLOCK = false;

      },

      (error) => {
        this.saveAsDraftPressed = false;
        this.showSpinnerDraft = false;
        this.LOCK_CALLS_UNLOCK = false;

        console.log(error);
        this.toastr.error(
          'Something went wrong while unlocking claim, please try again. ' +
          'Error code: ' +
          error.status,
          'Create claim',
          {
            positionClass: 'toast-top-center',
          }
        );
      }
    );
  }
}
